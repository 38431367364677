import { Controller } from "stimulus";
import $ from 'jquery';

require("select2/dist/css/select2");
require("select2-bootstrap-theme/dist/select2-bootstrap");

import Select2 from "select2";

export default class extends Controller {
  connect() {
    var testament= $('#testament');
    var short = $("#short");
    var chapt = $("#chapt");
    var vers = $("#vers");

    if (testament || short || chapt || vers) {
      testament.select2({ minimumResultsForSearch: Infinity, width: "100%", theme: "bootstrap" });
      short.select2({ width: "100%", theme: "bootstrap" });
      chapt.select2({ width: "100%", theme: "bootstrap" });
      vers.select2({ width: "100%", theme: "bootstrap" });
    }
  }

  teardown() {
    const destroyables = $(".select2-hidden-accessible");
    if (destroyables) { destroyables.select2("destroy"); }
  }

  disconnect() {
  }
}
