/* eslint no-console:0 */ // This file is automatically compiled by Webpack, along with any other files;
// present in this directory. You"re encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it"ll be compiled.
//
// To reference this file, add <%= javascript_pack_tag "application" %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import $ from "jquery";
import Rails from "@rails/ujs";
import TurboLinks from "turbolinks";
import "bootstrap-sass/assets/javascripts/bootstrap";
import "bootstrap-submenu/dist/js/bootstrap-submenu";
import "@fancyapps/fancybox/dist/jquery.fancybox.min";
import analytics from "universal-ga";
import "@fortawesome/fontawesome-free/js/fontawesome";

import "select2/dist/css/select2.css";
import "select2-bootstrap-theme/dist/select2-bootstrap.css";
import "bootstrap-submenu/dist/css/bootstrap-submenu.css";
import "@fancyapps/fancybox/dist/jquery.fancybox.css";

import "../scss/1st_load_framework.scss";
import "../javascripts/pagy.js.erb";

require.context("../images", true);
require.context("../images/favicon", true);
import "../javascripts/libros.js";

Rails.start();
TurboLinks.start();
analytics.initialize("G-HVSZ4PLQBC");

$(document).on("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip();
  $('.dropdown-toggle').dropdown({ trigger: 'hover' });
  $('[data-submenu]').submenupicker();
});

import "controllers";
