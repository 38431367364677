import { Controller } from "stimulus";
import $ from 'jquery';

export default class extends Controller {
  connect() {
    $("#chapt").on('select2:select', function () {
      let event = new Event('change', { bubbles: true }); // fire a native event
      this.dispatchEvent(event);
    });
  }

  updateToVerseOptions() {
    var vers = $("#vers");
    var requestData = {};

    requestData.short_id = $("#short option:selected").val();
    requestData.chapter = $("#chapt option:selected").val();
    vers.prop("disabled", true);
    $.getJSON("/update_verses.json", requestData, function(items) {
      let newOptions = '<option value=""></option>';
      for (
        let id = 1, end = items.length, asc = 1 <= end;
        asc ? id <= end : id >= end;
        asc ? id++ : id--
      ) {
        newOptions += `<option value="${id}">${id}</option>`;
      }
      vers
        .select2("destroy")
        .html(newOptions)
        .prop("disabled", false)
        .select2({
          placeholder: "--",
          width: "100%",
          theme: "bootstrap"
        });
    });
  }
}
