import { Controller } from "stimulus";
import $ from 'jquery';

export default class extends Controller {
  static values = {
    empty: String,
    title: String,
    verse: String,
  };

  remove_textus() {
    $("#textus").remove();
  }

  get_textus() {
    $.ajax({
      url: "https://getbible.net/json",
      dataType: "jsonp",
      data: "p=" + this.titleValue + "&v=text",
      jsonp: "getbible",
      success: function(json){
        let output = '';
        output += "<p class='lead'>Este versículo en otra versión dice así:</p>";
        output += "<p class='gw greek-bold interl text-success'>";
        jQuery.each(json.book, function(index, value) {
          jQuery.each(value.chapter, function(index, value) {
              output += value.verse;
          });
        });
        output += '</p>';
        $('.table-condensed').remove();
        $("#textus").html(output);
      },
      $error: function(){
        $("#textus").html("<h2 class='text-warning'>Hubo error. Recargue la página.</h2>");
      }
    });
  }

  connect() {
    if (this.emptyValue === 'true') {
      this.get_textus();
    } else {
      this.remove_textus();
    }
  }
}
