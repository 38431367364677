import { Controller } from "stimulus";
import $ from 'jquery';

export default class extends Controller {
  connect() {
    $("#vers").on('select2:select', function () {
      let event = new Event('change', { bubbles: true }); // fire a native event
      this.dispatchEvent(event);
    });
  }

  goToVerse() {
    var url = '/interlinear/' +
      document.getElementById('testament').value + '/' +
      document.getElementById('short').value + '/' +
      document.getElementById('chapt').value + '/' +
      document.getElementById('vers').value;
    location.href = url;
  }
}
