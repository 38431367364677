import { Controller } from "stimulus";
import $ from 'jquery';

export default class extends Controller {
  connect() {
    $("#testament").on('select2:select', function () {
      let event = new Event('change', { bubbles: true }); // fire a native event
      this.dispatchEvent(event);
    });
  }

  updateToShortOptions() {
    var short = $("#short");
    var requestData = {};

    requestData.testament = $("#testament option:selected").val();
    short.prop("disabled", true);
    $.getJSON("/update_books.json", requestData, function(items) {
      let newOptions = '<option value=""></option>';
      for (let i of Array.from(items)) {
        newOptions += `<option value="${i[1]}">${i[0]}</option>`;
      }
      short
        .select2("destroy")
        .html(newOptions)
        .prop("disabled", false)
        .select2({
          placeholder: "--",
          width: "100%",
          theme: "bootstrap"
        });
    });
  }
}
